!<template>
<div class="allHeader">


   <!-- <slot name="sideBar" class="sideBarButton">


   </slot> -->

      <a style='grid-column:2' href="https://github.com/MarkYnot" target="_blank" v-if="!isMobile"> 
          <!-- <img class="headerTilte" v-lazy="'/static/github1.png'" alt="headerTitle">    -->
        <a-icon type="github" class="headerTilte" theme="filled" :style="mainPage?'color: white' :'color: black'"/>
      </a>


      <a href="https://linkedin.com/in/junjie-lin-142444216" target="_blank" v-if="!isMobile">
        <!-- <img class="headerText" v-lazy="'/static/linkedin1.png'" alt="headerTitle"> -->
    <a-icon type="linkedin" class="headerText" theme="filled" :style="mainPage?'color: white' :'color: black'"/>

      </a>


      <slot name="search" >
         
      </slot>


        <a-menu
              theme="light"
              mode="horizontal"
              style="{lineHeight: 64px}"
              class="userInfoSlot"
              v-if="isMobile"
            >
                <a-sub-menu >
                    <span slot="title" >
                    <a-icon class="mobileList" type="menu" theme="outlined" :style="mainPage?'color: white' :'color: black'"/>
                    </span>

                    <a-menu-item key="setting:1">
                        <a href="https://github.com/MarkYnot" target="_blank">Github</a>
                      </a-menu-item>

                      <a-menu-item key="setting:2">
                        <a href="https://linkedin.com/in/junjie-lin-142444216" target="_blank">Linkedin</a>
                      </a-menu-item>
              
          
                </a-sub-menu>
            
            </a-menu>
   
      
</div>
</template>

<script>

  export default {

    data(){
       return {
          username: "Mark",
          photo: "",
          hasPhoto: false,
          photoCorrect: false,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight
       }

    }, 
   
   props:{
     mainPage:{
        type:Boolean,
        default:false
     }
   },

    beforeDestroy(){
        window.removeEventListener('resize', this.handleResize)
       
    },

    
    mounted(){
       window.addEventListener('resize', this.handleResize);
    },
    computed:{
       
          isMobile() {
            return this.windowWidth <=912 && this.windowHeight > 400; 
          }
    },
    methods:{
        onChange(checked) {
      // console.log(`a-switch to ${checked}`);
        },

        handleResize() {
          
          this.windowWidth = window.innerWidth;
          this.windowHeight = window.innerHeight;
        
        }     
    }
  }
</script>

<style lang="less" scoped>
.allHeader{
  height: 100%;
  padding: 0;
  line-height: 4.5vh;
  width: 100%;
  overflow: visible;
  text-align: center;  
  border-bottom: 0px;  
  border-top:0px;  
  border-left:0px;  
  border-right:0px;  
  display: grid;
  grid-template-columns: 50% 25% 5% 20%; 
  grid-template-rows: 100%;
       @media screen and (max-width:767px){
         grid-template-columns: 50% 20% 10% 20%; 
   }

}

/deep/ .ant-layout-header{
  height: 53px;
  padding: 0;
  line-height: 64px;
  width: 100vw;
  background: white;
  overflow: hidden;
  border-radius: 5px;
  -webkit-box-shadow: #666 0px 0px 10px;
  -moz-box-shadow: #666 0px 0px 10px;
  box-shadow: #666 0px 0px 10px;
}

.userInfoSlot{
  position: relative;
  margin-top: 11px;
  grid-column: 4;
  grid-row: 1;
    @media  screen and (max-width: 697px) {
       grid-column: 3/5 !important;
    }
}

//For ipad and sueface pro 7
@media screen and (min-width: 768px)and (max-width: 912px){
      .userInfoSlot{
        grid-column: 3/5 !important;
      }

      .mobileList{
        font-size:25px !important;
        margin-top: 8px;
      }
}


//for Galaxy fold
@media screen and (max-width: 280px){
     .mobileList{
       font-size: 10px !important;
     }

       .userInfoSlot{
        grid-column: 2/5 !important;
        margin-left: 20px;
        z-index: -1;
      }


}

//screen size big
@media screen and (min-width:1097px){
      .headerTilte{
        height: 28px !important;
        width: 30px !important;
     }

     .headerText{
        //  width: 42px !important;
        //  height: 45px !important;
        //  margin-top: 8px !important;
     }
}

.mobileList{
  font-size:21px;
  // color:white;
}


/deep/ .ant-menu-submenu{
   border-bottom: 0 !important; 
}


.sideBarButton{
   text-align: center;
   grid-column: 1;
   grid-row: 1;
}


/deep/ .ant-menu{
    background: none;
}

/deep/ .ant-menu-horizontal{
    line-height: 4vh;
    border-bottom: none;
}

.headerTilte{
  // float: right;
  // height: 18px;
  // width: 18px;
  margin: 17px 0;
  font-size: 30px;
  // color: black;
  grid-column: 2;
  grid-row: 1;
  margin-left: 13vw;
  /* animation: logo-icon-spin infinite 20s linear; */
}

.headerTilte:hover{
  transition: 0.3s;
  opacity: 0.6;
}

.headerText{
  // width: 28px;
  // height: 30px;
  font-size: 30px;
  border-radius: 50px;
  // color: black;
  margin: 17px 0;
}

.headerText:hover{
  transition: 0.3s;
  opacity: 0.6;
}



</style>